import { PageProps } from "gatsby"
import tw from "twin.macro"
import Seo from "../components/SEO"


const ResourcePDFS: React.FC<PageProps>= () => {
    
  return (
    <div>
      <Seo title="Resource PDFs" />
      <main css={[tw`py-12 h-[80vh] lg:h-[70vh] py-48 lg:w-2/3 px-5 lg:px-48 flex flex-col justify-center content-center mx-auto`]}>
        <div css={[tw`container mx-auto px-8`]}>
          <h6 css={[tw`mb-8 text-gray-700`]}>Resource PDFs</h6>
          <div
            css={[tw``]}
          />
          <p css={[tw`text-xl text-red-300 underline hover:text-red-600 pb-3`]}><a href="/uploads/Region1.pdf" target={"_blank"}>Region 1</a></p>
          <p css={[tw`text-xl text-red-300 underline hover:text-red-600 pb-3`]}><a href="/uploads/Region2.pdf" target={"_blank"}>Region 2</a></p>
          <p css={[tw`text-xl text-red-300 underline hover:text-red-600 pb-3`]}><a href="/uploads/Region3.pdf" target={"_blank"}>Region 3</a></p>
          <p css={[tw`text-xl text-red-300 underline hover:text-red-600 pb-3`]}><a href="/uploads/Region4.pdf" target={"_blank"}>Region 4</a></p>
          <p css={[tw`text-xl text-red-300 underline hover:text-red-600 pb-3`]}><a href="/uploads/Region5.pdf" target={"_blank"}>Region 5</a></p>
          <p css={[tw`text-xl text-red-300 underline hover:text-red-600 pb-3`]}><a href="/uploads/Region6.pdf" target={"_blank"}>Region 6</a></p>
          <p css={[tw`text-xl text-red-300 underline hover:text-red-600 pb-3`]}><a href="/uploads/Region7.pdf" target={"_blank"}>Region 7</a></p>
        
        </div>
      </main>
    </div>
  )
}


export default ResourcePDFS
